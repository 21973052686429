import { NavLink } from "solid-app-router";
import Dismiss from "solid-dismiss";
import { Component, createSignal } from "solid-js";
import favicon48 from "./assets/favicon-48.png";
import { useGamesDataContext } from "./GameDataProvider";
import {
  ChevronDownIcon,
  DonateIcon,
  HelpIcon,
  PatreonIcon,
  StatisticsIcon,
  CalendarIcon,
  DiceIcon,
  ArchivesIcon,
  ParametersIcon
} from "./icons";
import { GameMode } from "./types";
import { vibrate } from "./utils";

type HeaderProps = {
  mode: GameMode;
  onOpenTutorial: () => void;
  onOpenStatistics: () => void;
  onOpenParameters: () => void;
};
const Header: Component<HeaderProps> = (props) => {
  const [gamesData, gamesDataFuncs] = useGamesDataContext();

  const [open, setOpen] = createSignal(false);
  const [btnEl, setBtnEl] = createSignal<HTMLButtonElement>();
  /*
    none: "#d1d5db",
    diff: "#ffcc00",
    correct: "#00cc88",
  if(gamesData.blindMode){
    colorOptions.diff = "#60a5fa";
    colorOptions.correct = "#fb923c";
  }
  */
  return (
    <nav class="bg-gray-300 dark:bg-gray-900 w-screen border-b-2 border-white dark:border-gray-800">
      <div class="flex items-center max-w-[550px] m-auto px-4 py-2 relative">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24px" height="24px" 
        colorblind={gamesData.blindMode ? "true" : "false"}
        >
          <title>Quordle Fr Logo</title>
          <rect x="0" y="0" width="12" height="12" fill={gamesData.blindMode ? "#fb923c" : "#00cc88"}></rect>
          <rect x="12" y="0" width="12" height="12" fill="#021b9b"></rect>
          <rect x="16" y="0" width="12" height="12" fill="#ffffff"></rect>
          <rect x="20" y="0" width="12" height="12" fill="#ff002e"></rect>
          <rect x="0" y="12" width="12" height="12" fill={gamesData.blindMode ? "#60a5fa" : "#ffcc00"}></rect>
          <rect x="12" y="12" width="12" height="12" fill={gamesData.blindMode ? "#fb923c" : "#00cc88"}></rect>
        </svg>
        <span class="ml-2 text-black dark:text-white">Quordle</span>
        <div class="flex-1">
          <div class="">
            <div class="flex ml-2">
              <NavLink
                href="/"
                activeClass="quordle-nav-active"
                class="quordle-nav"
                onClick={() => vibrate(gamesData.vibrateMode)}
                title="Jour"
                end
              >
                <CalendarIcon />
              </NavLink>
              <NavLink
                href="/archives"
                activeClass="quordle-nav-active"
                class="quordle-nav"
                onClick={() => vibrate(gamesData.vibrateMode)}
                title="Archives"
                end
              >
                <ArchivesIcon />
              </NavLink>
              <NavLink
              href="/entrainement"
              activeClass="quordle-nav-active"
              class="quordle-nav"
              onClick={() => vibrate(gamesData.vibrateMode)}
              title="Aléatoire"
              end
            >
              <DiceIcon />
            </NavLink>
            </div>
          </div>
        </div>
        <div class="flex items-center sm:inset-auto sm:ml-6">
          <button
            type="button"
            title="Aide"
            class="bg-gray-700 dark:bg-gray-300 p-1 rounded-full text-white hover:text-gray-200 dark:text-gray-800 dark:hover:text-gray-900 transition-colors"
            onClick={props.onOpenTutorial}
          >
            <HelpIcon />
          </button>
          <button
          type="button"
          title="Voir plus"
          class="bg-gray-700 dark:bg-gray-300 p-1 ml-2 rounded-full text-white hover:text-gray-200 dark:text-gray-800 dark:hover:text-gray-900 transition-colors"
          ref={(ref) => setBtnEl(ref)}
          onClick={() => vibrate(gamesData.vibrateMode)}
        >
          <ChevronDownIcon />
        </button>
        </div>
        <Dismiss menuButton={btnEl} open={open} setOpen={setOpen}>
          <div
            class="absolute flex flex-col bg-gray-100 dark:bg-gray-800 text-black dark:text-white z-20 right-4 rounded-lg border-2 border-gray-400"
            style={{
              top: (btnEl()?.getBoundingClientRect().bottom || 0) + 12 + "px",
            }}
          >
            <button
            type="button"
            class="flex flex-row-reverse items-center px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 transition-all my-4"
            onClick={() => {
              setOpen(false);
              props.onOpenStatistics();
            }}
          >
            <StatisticsIcon />
            <div class="mr-3 text-black dark:text-white">
              Stats {props.mode === "free" ? "d'entrainement" : "quotidiennes"}
            </div>
          </button>
            <button
            type="button"
            class="flex flex-row-reverse items-center px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 transition-all my-4"
            onClick={() => {
              setOpen(false);
              props.onOpenParameters();
            }}
          >
            <ParametersIcon />
            <div class="mr-3 text-black dark:text-white">
              Paramètres
            </div>
          </button>
            
          </div>
        </Dismiss>
      </div>
    </nav>
  );
};

export default Header;
