import { Component, createMemo } from "solid-js";
import { ALPHABET, KEYBOARD_KEYS_AZERTY, KEYBOARD_KEYS_QWERTY } from "./constants";
import { useGamesDataContext } from "./GameDataProvider";
import { BoxState, GameMode } from "./types";
import { vibrate } from "./utils";

type KeyProps = {
  mode: GameMode;
  x: number;
  y: number;
  key: string;
};
const Key: Component<KeyProps> = (props) => {
  const [gamesData, gamesDataFuncs] = useGamesDataContext();

  const keyLower = createMemo(() => props.key.toLocaleLowerCase());
  
  const keyType = createMemo(() => {
    const guesses = gamesData[props.mode].guesses;

    let keyHasBeenGuessed = false;
    for (let g = 0; g < guesses.length; g++) {
      if (guesses[g].indexOf(keyLower()) >= 0) {
        keyHasBeenGuessed = true;
        break;
      }
    }
    
    let keyIsNotUtil = true;
    
    for (let c = 0; c < 4; c++) {
      const gameStates = gamesData[props.mode].states[c];
      for (let row = 0; row < gameStates.length; row++) {
        for (let col = 0; col < gameStates[row].length; col++) {
          if (keyLower() === guesses[row][col] && (gameStates[row][col] === "correct" ||  gameStates[row][col] === "diff")) {
            keyIsNotUtil = false;
          }
        }
      }
    }
    if (!keyHasBeenGuessed) {
      return(0);
    } else if(keyIsNotUtil) {
      return(1);
    } else {
      return(2);
    }
  });

  const keyStyle = createMemo(() => {
    const guesses = gamesData[props.mode].guesses;

    let keyHasBeenGuessed = false;
    for (let g = 0; g < guesses.length; g++) {
      if (guesses[g].indexOf(keyLower()) >= 0) {
        keyHasBeenGuessed = true;
        break;
      }
    }
    let percent = gamesData.keyboardHeight*10;
    if (!keyHasBeenGuessed) {
      return("padding-bottom:calc(" + percent + "% - 0.25rem);");
    }
    const colorOptions: { [key in BoxState]: string } = {
      none: "#d1d5db",
      diff: "#ffcc00",
      correct: "#00cc88",
    };
    if(gamesData.darkMode){
      colorOptions.none = "#9ca3af";
    }
    if(gamesData.blindMode){
      colorOptions.diff = "#60a5fa";
      colorOptions.correct = "#fb923c";
    }
    
    const keyColors = [
      colorOptions.none,
      colorOptions.none,
      colorOptions.none,
      colorOptions.none,
    ];
    for (let c = 0; c < keyColors.length; c++) {
      const gameStates = gamesData[props.mode].states[c];
      for (let row = 0; row < gameStates.length; row++) {
        for (let col = 0; col < gameStates[row].length; col++) {
          if (
            keyLower() === guesses[row][col] &&
            (gameStates[row][col] === "correct" ||
              gameStates[row][col] === "diff")
          ) {
            if (gameStates[row][col] === "correct") {
              keyColors[c] = colorOptions.correct;
            } else if (
              gameStates[row][col] === "diff" &&
              keyColors[c] !== colorOptions.correct
            ) {
              keyColors[c] = colorOptions.diff;
            }
          }
        }
      }
    }
    if((keyColors[1] == colorOptions.none)&&(keyColors[2] == colorOptions.none)&&(keyColors[3] == colorOptions.none)&&(keyColors[0] == colorOptions.none)){
      return("padding-bottom:calc(" + percent + "% - 0.25rem);");
    } else {
      return ("background:conic-gradient(" + keyColors[1] + " 0deg 90deg, " + keyColors[3] + " 90deg 180deg, " + keyColors[2] + " 180deg 270deg, " + keyColors[0] + " 270deg 360deg);padding-bottom:calc("+ percent + "% - 0.25rem);");
    }
  });
  return (
    <button
      class="quordle-key w-[calc(10%-0.25rem)]"
      classList={{
        "w-[calc(20%-0.25rem)]": props.key == "bs1" || props.key == "enter1",
        "w-[calc(15%-0.25rem)]": props.key == "bs2" || props.key == "enter2",
        "border-gray-300 dark:border-gray-700 text-blue-300 dark:text-cyan-600 bg-blue-100 dark:bg-cyan-800 border-blue-200 dark:border-cyan-900": keyType() == 1,
        "border-gray-300 dark:border-gray-700 text-black dark:text-white bg-white dark:bg-gray-500": keyType() == 0,
        "border-gray-300 dark:border-gray-700 text-black dark:text-black border-gray-400": keyType() == 2,
      }}
      style={keyStyle()}
      onClick={() => {
        vibrate(gamesData.vibrateMode);
        gamesDataFuncs.sendKey(
          props.mode,
          new KeyboardEvent("keydown", {
            keyCode: props.key.startsWith("enter")
              ? 13
              : props.key.startsWith("bs")
              ? 8
              : ALPHABET.indexOf(props.key.toLocaleLowerCase()) + 65,
            key: props.key.startsWith("enter")
              ? "Enter"
              : props.key.startsWith("bs")
              ? "Backspace"
              : props.key.toLocaleLowerCase(),
          })
        );
      }}
    >
      <div
        class="quordle-box-content"
        textContent={
          props.key.startsWith("enter")
            ? "\u23CE"
            : props.key.startsWith("bs")
            ? "\u232B"
            : props.key
        }
      />
    </button>
  );
};

type KeyboardProps = {
  mode: GameMode;
};

const Keyboard: Component<KeyboardProps> = (props) => {
  const [gamesData] = useGamesDataContext();
  return (
    <div class="w-full flex-col p-1 pb-1.5 bg-blue-200 dark:bg-cyan-900 rounded-t shadow">
        {(gamesData.qwertyMode?KEYBOARD_KEYS_QWERTY:KEYBOARD_KEYS_AZERTY).map((row, y) => (
          <div class="flex w-full justify-center">
            {row.map((key, x) => (
              <Key mode={props.mode} x={x} y={y} key={key} />
            ))}
          </div>
        ))}
    </div>
  );
};


export default Keyboard;
