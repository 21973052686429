import { Component } from "solid-js";
import { GameTileRenderer } from "./GameTile";
import {
  FacebookIcon,
  GithubIcon,
  InstagramIcon,
  RedditIcon,
  TwitterIcon,
} from "./icons";
import { BoxState, GameMode } from "./types";

type TutorialWordType = [string, BoxState[]];
const TUTORIAL_WORDS: TutorialWordType[] = [
  ["POINT", ["correct", "none", "none", "none", "none"]],
  ["ANGLE", ["none", "diff", "none", "none", "none"]],
  ["CORDE", ["none", "none", "none", "none", "none"]],
  ["MATHS", ["none", "none", "none", "none", "none"]],
  ["MATHS", ["none", "none", "diff", "none", "correct"]],
  ["MATHS", ["none", "none", "none", "diff", "none"]],
  ["MATHS", ["none", "correct", "none", "none", "diff"]],
];

type TutorialWordProps = {
  word: TutorialWordType;
};
const TutorialWord: Component<TutorialWordProps> = (props) => {
  return props.word[0]
    .split("")
    .map((letter, i) => (
      <GameTileRenderer
        mode={false}
        state={props.word[1][i]}
        letter={letter}
        placeholder={""}
        gameRow={0}
        gameCol={i}
        actualCol={i}
        rowTemporalState="past"
      />
    ));
};

type TutorialProps = {
  mode: GameMode;
  onCloseTutorial: () => void;
};
const Tutorial: Component<TutorialProps> = (props) => {
  return (
    <div class="w-full h-full overflow-auto">
      <div class="max-w-[550px] w-full m-auto flex flex-row-reverse pr-4 pt-2">
        <button
          type="button"
          class="bg-white dark:bg-gray-800 p-1 rounded-full text-gray-900 hover:text-black dark:text-gray-400 dark:hover:text-white"
          onClick={props.onCloseTutorial}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="max-w-[550px] m-auto w-full px-6">
        <div class="text-3xl mt-2 mb-1">
          Trouvez les 4 mots à la fois en 9 essais maximum.
        </div>
        <div class="text-base">
          Chaque essai doit être un mot valide. Appuyez sur la touche "Entrée" [&#9166;] pour
          valider. Après chaque essai, la couleur des tuiles vous indique les lettres qui sont
          bien placées et celles qui sont mal placées.
        </div>
        <div class="text-3xl mt-4 mb-2">Exemples</div>
        <div class="flex w-[50%] my-2">
          <TutorialWord word={TUTORIAL_WORDS[0]} />
        </div>
        <div class="text-base mb-6">
          La lettre P est présente dans le mot cherché à cette place.
        </div>
        <div class="flex w-[50%] my-2">
          <TutorialWord word={TUTORIAL_WORDS[1]} />
        </div>
        <div class="text-base mb-6">
          La lettre N est présente dans dans le mot cherché mais pas à cette place.
        </div>
        <div class="flex w-[50%] my-2">
          <TutorialWord word={TUTORIAL_WORDS[2]} />
        </div>
        <div class="text-base mb-6">
          Les lettres C, O, R, D, E ne sont pas présentes dans le mot cherché.
        </div>
        <div class="text-base mb-6">
          Quand vous faites un essai dans ce jeu, vous testez ce mot 
          pour les quatre à deviner. Ces quatre mots à trouver sont différents.
        </div>
        <div class="text-base">Pour cet essai avec le mot "MATHS" :</div>
        <div class="flex w-[100%] mb-2">
          <div class="flex w-[50%] mr-1">
            <TutorialWord word={TUTORIAL_WORDS[3]} />
          </div>
          <div class="flex w-[50%] ml-1">
            <TutorialWord word={TUTORIAL_WORDS[4]} />
          </div>
        </div>
        <div class="flex w-[100%] mt-2 mb-2">
          <div class="flex w-[50%] mr-1">
            <TutorialWord word={TUTORIAL_WORDS[5]} />
          </div>
          <div class="flex w-[50%] ml-1">
            <TutorialWord word={TUTORIAL_WORDS[6]} />
          </div>
        </div>
        <ol class="text-base list-decimal ml-8 mb-6">
          <li>Le mot en haut à gauche ne contient aucune lettre de "MATHS".</li>
          <li>Le mot en haut à droite contient le T à une autre place et le S
            à cette place.
          </li>
          <li>Le mot en bas à gauche contient le H à une autre place.</li>
          <li>Le mot en bas à droite contient le A à cette place et le S
            à une autre place.
          </li>
        </ol>
        <div class="text-base mb-6">
          Vous n'avez que 9 essais pour trouver les 4 mots à la fois. Ce ne sera pas si facile...
        </div>
        <div class="text-base mb-8">
          Un nouveau défi quotidien (commun à tous) est disponible chaque jour à minuit.  Bonne chance !
        </div>
        <div class="text-base mb-4 text-center">Développé par Nicolas Desmaths.fr</div>
          <div class="flex flex-row items-center justify-center">
          <a class="px-4" href="https://twitter.com/QuordleFr" target="_blank"><TwitterIcon height={36} /></a>
          </div>
          <div class="flex flex-row items-center justify-center mb-4">
          <a class="text-base" href="https://twitter.com/QuordleFr" target="_blank">@QuordleFr</a>
          </div>
          <div class="flex flex-row items-center justify-center">
          <a class="px-4" href="https://twitter.com/DesmathsFr" target="_blank"><TwitterIcon height={36} /></a>
          </div>
          <div class="flex flex-row items-center justify-center mb-4">
          <a class="text-base" href="https://twitter.com/DesmathsFr" target="_blank">@DesmathsFr</a>
          </div>
          <div class="flex flex-row items-center justify-center">
            <a class="px-4" href="https://github.com/desmathsfr/QuordleFr" target="_blank"><GithubIcon height={48} /></a>
          </div>
          <div class="flex flex-row items-center justify-center mb-4">
            <a class="text-base" href="https://github.com/desmathsfr/QuordleFr" target="_blank">QuordleFr</a>
          </div>
          <div class="text-base mb-4 text-center">Basé sur le travail de Freddie Meyer</div>
          <div class="flex flex-row items-center justify-center">
            <a class="px-4" href="https://github.com/fireph/quordle" target="_blank"><GithubIcon height={48} /></a>
          </div>
          <div class="flex flex-row items-center justify-center mb-4">
            <a class="text-base" href="https://github.com/fireph/quordle" target="_blank">Quordle</a>
          </div>
        </div>
      </div>
  );
};

export default Tutorial;
