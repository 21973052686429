import { Component, createSignal, createMemo } from "solid-js";
import { ALLOWED_SET, ANSWERS_SET, NUM_GAMES_X } from "./constants";
import { useGamesDataContext } from "./GameDataProvider";
import { createResizeObserverPoly } from "./ResizeObserverPoly";
import { BoxState, GameMode } from "./types";

type GameTileState = BoxState | "invalid";
type TemporalState = "past" | "present" | "future" | "never" | "nevernow";

type GameTileRendererProps = {
  mode: GameMode;
  gameRow: number;
  gameCol: number;
  actualCol: number;
  state: GameTileState;
  letter: string;
  placeholder: string;
  rowTemporalState: TemporalState;
};
export const GameTileRenderer: Component<GameTileRendererProps> = (props) => {
  const [gamesData, gamesDataFuncs] = useGamesDataContext();

  const widthBox = createMemo(() => {
    const wscreen = gamesData.width;
    const hscreen = gamesData.height;
    const hsquare = (wscreen-56)/10;
    if(!props.mode){
      return("height:" + hsquare + "px; font-size:" + (hsquare/1.25) + "px;");
    }
    let harchives = props.mode === "archives" ? 42 : 0;
    let hfit = gamesDataFuncs.isGameComplete(props.mode) ? Math.max(16, (hscreen - 90 - harchives)/18) : Math.max(16, (hscreen - hsquare*2 - 90 - harchives)/16);
    if((gamesData.fitMode == false)||(props.rowTemporalState == "present")||((props.rowTemporalState === "nevernow")&&((!gamesDataFuncs.isGameComplete(props.mode))))){
      return("height:" + hsquare + "px; font-size:" + (hsquare/1.25) + "px;");
    } else {
      return("font-weight:bold;height:" + Math.min(hfit, hsquare) + "px; font-size:" + (Math.min(hfit, hsquare)/1.25) + "px;");
    }
  });
  
  return (
    <div
      class="quordle-box w-[20%]"
      classList={{
        "bg-box-correct": props.state === "correct" && !gamesData.blindMode,
        "bg-box-diff": props.state === "diff" && !gamesData.blindMode,
        "bg-box-correct-alt": props.state === "correct" && gamesData.blindMode,
        "bg-box-diff-alt": props.state === "diff" && gamesData.blindMode,
        "bg-gray-200 dark:bg-gray-700":
          props.state === "none" && props.rowTemporalState === "past",
        "bg-gray-300 dark:bg-gray-600": props.rowTemporalState === "present",
        "bg-gray-100 dark:bg-gray-900": props.rowTemporalState === "future" || props.rowTemporalState === "never" || props.rowTemporalState === "nevernow",
        "text-black": props.state === "correct" || props.state === "diff",
        "text-rose-600": props.state === "invalid",
        "text-gray-400 dark:text-gray-500 text-italic": props.letter === "?",
        "text-black dark:text-white": props.state === "none",
        "quordle-box-connected": props.rowTemporalState == "future" || props.rowTemporalState === "never" || (props.rowTemporalState === "nevernow" && gamesDataFuncs.isGameComplete(props.mode)),
        "quordle-heartbeat-anim dark:quordle-heartbeat-anim-dark": props.rowTemporalState === "present" && props.gameCol == props.actualCol,
        //"text-[0.75em] font-bold pb-[calc(15%-0.25rem)] h-[0.75rem]": gamesData.displayMode == 1 && props.rowTemporalState !== "present" && props.rowTemporalState !== "nevernow",
        //"text-[0.5em] font-bold pb-[calc(10%-0.25rem)] h-[0.5rem]": gamesData.displayMode == 2 && props.rowTemporalState !== "present" && props.rowTemporalState !== "nevernow",
        //"pb-[calc(20%-0.25rem)]": gamesData.displayMode == 0 || props.rowTemporalState === "present" || props.rowTemporalState === "nevernow",
      }}
      style={widthBox()}
    >
      <div class="quordle-box-placeholder" textContent={props.letter == "" && gamesData.hintMode ? props.placeholder : ""} />
      <div class="quordle-box-content" textContent={props.letter != "" ? props.letter : ""} />
    </div>
  );
};

type GameTileProps = {
  mode: GameMode;
  gameX: number;
  gameY: number;
  gameCol: number;
  gameRow: number;
};
const GameTile: Component<GameTileProps> = (props) => {
  const gameIndex = props.gameX + props.gameY * NUM_GAMES_X;

  const [gamesData] = useGamesDataContext();

  const actualCol = createMemo(() => {
    const gameData = gamesData[props.mode];
    const current = gameData.current;
    return current.length;
  });
  
  const shouldRenderLetter = createMemo(() => {
    const gameData = gamesData[props.mode];
    const current = gameData.current;
    const guesses = gameData.guesses;
    const answer = gameData.answers[gameIndex];
    const answerIndex = guesses.indexOf(answer);
    return (
      props.gameRow <= answerIndex ||
      (answerIndex === -1 && props.gameRow < guesses.length) ||
      (answerIndex === -1 &&
        props.gameRow === guesses.length &&
        props.gameCol < current.length)
    );
  });

  const letter = createMemo(() => {
    const gameData = gamesData[props.mode];
    const guesses = gameData.guesses;
    const current = gameData.current;
    let letter: string = "";
    if (!shouldRenderLetter()) {
      return letter;
    } else if (props.gameRow < guesses.length) {
      letter = guesses[props.gameRow][props.gameCol];
    } else if (props.gameRow === guesses.length) {
      letter = current[props.gameCol];
    }
    return letter.toUpperCase();
  });

  const gameTileState = createMemo((): GameTileState => {
    const gameData = gamesData[props.mode];
    const guesses = gameData.guesses;
    const states = gameData.states;
    const current = gameData.current;
    if (shouldRenderLetter()) {
      if (props.gameRow < guesses.length) {
        return states[gameIndex][props.gameRow]?.[props.gameCol] || "none";
      } else if ( current.indexOf("?") > -1 ) {
        return "none";
      } else if ( props.gameRow === guesses.length && current.length === 5 && !ALLOWED_SET.has(current) && !ANSWERS_SET.has(current) ) {
        return "invalid";
      }
    }
    return "none";
  });
  
  const shouldRenderPlaceholder = createMemo(() => {
    const gameData = gamesData[props.mode];
    const current = gameData.current;
    const guesses = gameData.guesses;
    const answer = gameData.answers[gameIndex];
    const answerIndex = guesses.indexOf(answer);
    return (
      props.gameRow <= answerIndex ||
      (answerIndex === -1 && props.gameRow < guesses.length) ||
      (answerIndex === -1 && props.gameRow === guesses.length)
    );
  });
  
  const placeholder = createMemo(() => {
    const gameData = gamesData[props.mode];
    const guesses = gameData.guesses;
    const answer = gameData.answers[gameIndex];
    if (!shouldRenderPlaceholder()) {
     return "";
    }
    for(var i = 0; i < guesses.length ; i++){
      if(guesses[i][props.gameCol] == answer[props.gameCol]){
        return answer[props.gameCol].toUpperCase();
      }
    }
    return "";
  });

  const temporalState = createMemo((): TemporalState => {
    const gameData = gamesData[props.mode];
    const guesses = gameData.guesses;
    const answerIndex = gameData.answersCorrect[gameIndex];

    // Have we already answered this row? We will never guess post-answer
    if ((props.gameRow === guesses.length)&&(answerIndex !== -1 && answerIndex < props.gameRow)) return "nevernow";
    
    if (answerIndex !== -1 && answerIndex < props.gameRow) return "never";

    if (guesses.length > props.gameRow) return "past";

    if (props.gameRow === guesses.length) return "present";
    return "future";
  });

  return (
    <GameTileRenderer
      mode={props.mode}
      state={gameTileState()}
      letter={letter()}
      placeholder={placeholder()}
      actualCol={actualCol()}
      gameRow={props.gameRow}
      gameCol={props.gameCol}
      rowTemporalState={temporalState()}
    />
  );
};

export default GameTile;
