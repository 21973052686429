import { Component, createMemo, createSignal } from "solid-js";
import { GAME_ROWS, NUM_GAMES } from "./constants";
import { useGamesDataContext } from "./GameDataProvider";
import { GameMode } from "./types";
import { vibrate } from "./utils";

type ParametersProps = {
  mode: GameMode;
  onCloseParameters: () => void;
};
const Parameters: Component<ParametersProps> = (props) => {
  const [gamesData, gamesDataFuncs] = useGamesDataContext();
  return (
    <div class="w-full h-full overflow-auto">
      <div class="max-w-[550px] w-full m-auto flex flex-row-reverse pr-4 pt-2">
        <button
          type="button"
          class="bg-white dark:bg-gray-800 p-1 rounded-full text-gray-900 hover:text-black dark:text-gray-400 dark:hover:text-white"
          onClick={props.onCloseParameters}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="max-w-[550px] m-auto w-full px-6 mb-8">
        <div class="text-4xl mt-2 mb-4 text-center">
          Paramètres
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleDarkMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleDarkMode"
                class="sr-only"
                checked={gamesData.darkMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setDarkMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct": gamesData.darkMode && !gamesData.blindMode,
                  "translate-x-[100%] bg-box-correct-alt": gamesData.darkMode && gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Mode sombre</div>
          </label>
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleBlindMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleBlindMode"
                class="sr-only"
                checked={gamesData.blindMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setBlindMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct-alt": gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Mode daltonien</div>
          </label>
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleQwertyMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleQwertyMode"
                class="sr-only"
                checked={gamesData.qwertyMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setQwertyMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct": gamesData.qwertyMode && !gamesData.blindMode,
                  "translate-x-[100%] bg-box-correct-alt": gamesData.qwertyMode && gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Clavier Qwerty</div>
          </label>
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleVibrateMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleVibrateMode"
                class="sr-only"
                checked={gamesData.vibrateMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setVibrateMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct": gamesData.vibrateMode && !gamesData.blindMode,
                  "translate-x-[100%] bg-box-correct-alt": gamesData.vibrateMode && gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Vibrations</div>
          </label>
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleHintMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleHintMode"
                class="sr-only"
                checked={gamesData.hintMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setHintMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct": gamesData.hintMode && !gamesData.blindMode,
                  "translate-x-[100%] bg-box-correct-alt": gamesData.hintMode && gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Affichage lettres trouvées</div>
          </label>
        </div>
        <div class="text-base text-center m-4">
          <label
            for="toggleSharingMode"
            class="flex items-center cursor-pointer"
          >
            <div class="relative">
              <input
                type="checkbox"
                id="toggleSharingMode"
                class="sr-only"
                checked={gamesData.sharingMode}
                onClick={() => vibrate(gamesData.vibrateMode)}
                onChange={(e) =>
                  gamesDataFuncs.setSharingMode(e.currentTarget.checked)
                }
              />
              <div class="block bg-gray-500 dark:bg-gray-600 w-14 h-8 rounded-full"></div>
              <div
                class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                classList={{
                  "translate-x-[100%] bg-box-correct": gamesData.sharingMode && !gamesData.blindMode,
                  "translate-x-[100%] bg-box-correct-alt": gamesData.sharingMode && gamesData.blindMode,
                }}
              ></div>
            </div>
            <div class="ml-3 text-black dark:text-white">Partage jeu complet</div>
          </label>
        </div>
        <div class="text-base text-black dark:text-white m-4">
          <div class="relative">
            <label for="keyboardHeightRange">Affichage :</label>
            <select class="bg-gray-50 border border-gray-400 text-black rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer appearance-none" onChange={(e) =>
                gamesDataFuncs.setFitMode(e.currentTarget.value === "true")
              }>
                <option value="true" selected={gamesData.fitMode === true}>Adapté à l'écran (tuiles rectangulaires)</option>
                <option value="false" selected={gamesData.fitMode === false}>Avec défilement (tuiles carrées)</option>
              </select>
            </div>
        </div>
        <div class="text-base text-black dark:text-white m-4">
          <div class="relative">
            <label for="keyboardHeightRange">Hauteur du clavier (&times; <span id="valHeightRange">{gamesData.keyboardHeight}) :</span></label>
            <input type="range" class="appearance-none w-full h-2 rounded bg-gray-300 dark:bg-gray-600 cursor-pointer"
            classList={{
              "quordle-range": !gamesData.blindMode,
              "quordle-range-alt": gamesData.blindMode,
            }}
             min="0.8" max="1.5" step="0.1" id="keyboardHeightRange" value={gamesData.keyboardHeight} onChange={(e) =>
              (gamesDataFuncs.setKeyboardHeight(parseFloat(e.currentTarget.value)))
            }/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parameters;
