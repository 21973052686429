import { Component, JSX } from "solid-js";

type TwitterIconProps = {
  height?: number;
};
export const TwitterIcon: Component<TwitterIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={(props.height ? props.height : 16) + "px"}
      viewBox="0 0 248 204"
      fill="currentColor"
    >
      <g>
        <path
          d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
		C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
		c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
		c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
		c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
		c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
        />
      </g>
    </svg>
  );
};

type GithubIconProps = {
  height?: number;
};
export const GithubIcon: Component<GithubIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={(props.height ? props.height : 24) + "px"}
      width={(props.height ? props.height : 24) + "px"}
      fill="currentColor"
    >
      <path d="M10.9,2.1c-4.6,0.5-8.3,4.2-8.8,8.7c-0.5,4.7,2.2,8.9,6.3,10.5C8.7,21.4,9,21.2,9,20.8v-1.6c0,0-0.4,0.1-0.9,0.1 c-1.4,0-2-1.2-2.1-1.9c-0.1-0.4-0.3-0.7-0.6-1C5.1,16.3,5,16.3,5,16.2C5,16,5.3,16,5.4,16c0.6,0,1.1,0.7,1.3,1c0.5,0.8,1.1,1,1.4,1 c0.4,0,0.7-0.1,0.9-0.2c0.1-0.7,0.4-1.4,1-1.8c-2.3-0.5-4-1.8-4-4c0-1.1,0.5-2.2,1.2-3C7.1,8.8,7,8.3,7,7.6C7,7.2,7,6.6,7.3,6 c0,0,1.4,0,2.8,1.3C10.6,7.1,11.3,7,12,7s1.4,0.1,2,0.3C15.3,6,16.8,6,16.8,6C17,6.6,17,7.2,17,7.6c0,0.8-0.1,1.2-0.2,1.4 c0.7,0.8,1.2,1.8,1.2,3c0,2.2-1.7,3.5-4,4c0.6,0.5,1,1.4,1,2.3v2.6c0,0.3,0.3,0.6,0.7,0.5c3.7-1.5,6.3-5.1,6.3-9.3 C22,6.1,16.9,1.4,10.9,2.1z" />
    </svg>
  );
};

type FacebookIconProps = {
  height?: number;
};
export const FacebookIcon: Component<FacebookIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      height={(props.height ? props.height : 24) + "px"}
      width={(props.height ? props.height : 24) + "px"}
      fill="currentColor"
    >
      <path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z" />
    </svg>
  );
};

type RedditIconProps = {
  height?: number;
};
export const RedditIcon: Component<RedditIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={(props.height ? props.height : 24) + "px"}
      width={(props.height ? props.height : 24) + "px"}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z" />
    </svg>
  );
};

type InstagramIconProps = {
  height?: number;
};
export const InstagramIcon: Component<InstagramIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={(props.height ? props.height : 24) + "px"}
      width={(props.height ? props.height : 24) + "px"}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </svg>
  );
};

export const HelpIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const ChevronDownIcon: Component<JSX.IntrinsicElements["svg"]> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
};

export const PrevIcon: Component<JSX.IntrinsicElements["svg"]> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M16.071,5.57C16.414,5.218 16.406,4.655 16.054,4.312C15.702,3.97 15.139,3.978 14.797,4.33L7.931,11.41L8.569,12.03L7.929,11.409C7.586,11.763 7.594,12.327 7.948,12.67C7.958,12.68 7.969,12.689 7.979,12.699L14.797,19.67C15.139,20.022 15.702,20.03 16.054,19.688C16.406,19.345 16.414,18.782 16.071,18.43L9.809,12.027L16.071,5.57Z"
        transform="translate(-1 0)"
      />
    </svg>
  );
};

export const NextIcon: Component<JSX.IntrinsicElements["svg"]> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M7.929,18.43C7.586,18.782 7.594,19.345 7.946,19.688C8.298,20.03 8.861,20.022 9.203,19.67L16.069,12.59L15.431,11.97L16.071,12.591C16.414,12.237 16.406,11.673 16.052,11.33C16.042,11.32 16.031,11.311 16.021,11.301L9.203,4.33C8.861,3.978 8.298,3.97 7.946,4.312C7.594,4.655 7.586,5.218 7.929,5.57L14.191,11.973L7.929,18.43Z"
        transform="translate(1 0)"
      />
    </svg>
  );
};

export const StartIcon: Component<JSX.IntrinsicElements["svg"]> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M17.896,5.57C18.239,5.218 18.231,4.655 17.879,4.312C17.527,3.97 16.964,3.978 16.622,4.33L9.756,11.41L10.394,12.03L9.754,11.409C9.411,11.763 9.419,12.327 9.773,12.67C9.783,12.68 9.794,12.689 9.804,12.699L16.622,19.67C16.964,20.022 17.527,20.03 17.879,19.688C18.231,19.345 18.239,18.782 17.896,18.43L11.634,12.027L17.896,5.57ZM7.506,19.206C7.506,19.662 7.136,20.033 6.679,20.033C6.222,20.033 5.852,19.662 5.852,19.206L5.852,4.794C5.852,4.338 6.222,3.967 6.679,3.967C7.136,3.967 7.506,4.338 7.506,4.794L7.506,19.206Z"
      />
    </svg>
  );
};

export const EndIcon: Component<JSX.IntrinsicElements["svg"]> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M6.104,18.43C5.761,18.782 5.769,19.345 6.121,19.688C6.473,20.03 7.036,20.022 7.378,19.67L14.244,12.59L13.606,11.97L14.246,12.591C14.589,12.237 14.581,11.673 14.227,11.33C14.217,11.32 14.206,11.311 14.196,11.301L7.378,4.33C7.036,3.978 6.473,3.97 6.121,4.312C5.769,4.655 5.761,5.218 6.104,5.57L12.366,11.973L6.104,18.43ZM16.494,4.794C16.494,4.338 16.864,3.967 17.321,3.967C17.778,3.967 18.148,4.338 18.148,4.794L18.148,19.206C18.148,19.662 17.778,20.033 17.321,20.033C16.864,20.033 16.494,19.662 16.494,19.206L16.494,4.794Z"
      />
    </svg>
  );
};

export const DonateIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
      />
    </svg>
  );
};

export const PatreonIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M124 7408.71c0-8.08 10.32-13.186 16.916-6.892 3.532 3.36 4.143 8.902 1.248 12.951-3.591 4.92-8.282 4.193-12.76 4.193v-9.588c.041-2.117.747-3.943 3.324-4.816 2.245-.664 4.863.581 5.653 2.947.832 2.533-.374 4.234-1.787 5.272-1.413 1.039-3.616 1.039-5.07.042v3.279c3.138 1.5 8.105-.303 9.684-4.4 1.08-2.864.332-6.185-1.912-8.26-2.701-2.2-5.653-2.74-8.811-1.204-2.204 1.12-3.741 3.404-4.116 5.894v10.834h-2.327L124 7408.71z"
        transform="translate(-124 -7399)"
      />
    </svg>
  );
};

export const ArchivesIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"
      />
    </svg>
  );
};

export const CalendarIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
      />
    </svg>
  );
};

export const DiceIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM12 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
      />
    </svg>
  );
};

export const ParametersIcon: Component<JSX.IntrinsicElements["svg"]> = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M13.756,3.313C13.24,1.563 10.76,1.563 10.244,3.313L10.119,3.738C9.89,4.514 9.173,5.051 8.363,5.051C8.057,5.051 7.756,4.974 7.487,4.828L7.1,4.615C5.496,3.743 3.742,5.496 4.616,7.099L4.827,7.488C5.385,8.513 4.856,9.789 3.737,10.119L3.312,10.244C1.562,10.76 1.562,13.24 3.312,13.756L3.737,13.881C4.514,14.11 5.051,14.827 5.051,15.637C5.051,15.943 4.974,16.244 4.827,16.513L4.615,16.9C3.742,18.504 5.496,20.258 7.099,19.384L7.487,19.173C7.756,19.026 8.057,18.949 8.363,18.949C9.173,18.949 9.89,19.486 10.119,20.263L10.244,20.688C10.76,22.438 13.24,22.438 13.756,20.688L13.881,20.263C14.11,19.486 14.827,18.949 15.637,18.949C15.943,18.949 16.244,19.026 16.513,19.173L16.9,19.385C18.504,20.258 20.258,18.504 19.384,16.901L19.173,16.513C19.026,16.244 18.949,15.943 18.949,15.637C18.949,14.827 19.486,14.11 20.263,13.881L20.688,13.756C22.438,13.24 22.438,10.76 20.688,10.244L20.263,10.119C19.486,9.89 18.949,9.173 18.949,8.363C18.949,8.057 19.026,7.756 19.173,7.488L19.385,7.1C20.258,5.496 18.504,3.743 16.901,4.616L16.513,4.828C16.244,4.974 15.943,5.051 15.637,5.051C14.827,5.051 14.11,4.514 13.881,3.738L13.756,3.313ZM12,15.663C9.991,15.663 8.337,14.009 8.337,12C8.337,9.991 9.991,8.338 12,8.338C14.009,8.338 15.661,9.99 15.661,11.999C15.661,14.007 14.009,15.66 12,15.66L12,15.663Z"/>
    </svg>
  );
};

export const ShareIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-[20px] w-[20px]"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
      />
    </svg>
  );
};

export const LinkIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-[20px] w-[20px]"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style="display:inline-block"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M20.025,13.828C20.025,13.308 20.448,12.886 20.967,12.886C21.487,12.886 21.909,13.308 21.909,13.828L21.909,19.238C21.909,20.005 21.597,20.703 21.091,21.208C20.586,21.713 19.888,22.026 19.121,22.026L4.789,22.026C4.021,22.026 3.323,21.713 2.818,21.208C2.313,20.703 2,20.005 2,19.238L2,4.801C2,4.033 2.313,3.336 2.818,2.831C3.323,2.325 4.021,2.012 4.789,2.012L10.164,2.012C10.683,2.012 11.106,2.434 11.106,2.954C11.106,3.474 10.683,3.896 10.164,3.896L4.789,3.896C4.541,3.896 4.316,3.999 4.151,4.162C3.987,4.327 3.886,4.552 3.886,4.799L3.886,19.236C3.886,19.484 3.988,19.709 4.151,19.873C4.316,20.038 4.541,20.139 4.789,20.139L19.122,20.139C19.37,20.139 19.595,20.036 19.76,19.873C19.924,19.71 20.025,19.484 20.025,19.236L20.025,13.828ZM20.322,4.845L10.802,14.483C10.439,14.853 9.843,14.858 9.473,14.495C9.103,14.131 9.098,13.535 9.461,13.165L18.614,3.896L14.804,3.896C14.284,3.896 13.862,3.474 13.862,2.954C13.862,2.434 14.284,2.012 14.804,2.012L19.122,2.012C19.957,2.012 21.033,1.871 21.673,2.518C22.077,2.927 21.988,6.188 21.936,8.077C21.922,8.566 21.911,8.939 21.911,9.206C21.911,9.726 21.489,10.148 20.969,10.148C20.449,10.148 20.027,9.726 20.027,9.206C20.027,9.156 20.04,8.665 20.058,8.026C20.084,7.042 20.242,5.738 20.322,4.845Z"
      />
    </svg>
  );
};


export const ClipboardCopyIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-[20px] w-[20px]"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
      />
    </svg>
  );
};

export const SaveIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-[20px] w-[20px]"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
      />
    </svg>
  );
};

export const PlusIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-[20px] w-[20px]"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 4v16m8-8H4"
      />
    </svg>
  );
};

export const StatisticsIcon: Component = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M2,16.286C2,15.502 2.645,14.857 3.429,14.857L6.286,14.857C7.069,14.857 7.714,15.502 7.714,16.286L7.714,20.571C7.714,21.355 7.069,22 6.286,22L3.429,22C2.645,22 2,21.355 2,20.571L2,16.286ZM9.143,10.571C9.143,9.788 9.788,9.143 10.571,9.143L13.429,9.143C14.212,9.143 14.857,9.788 14.857,10.571L14.857,20.571C14.857,21.355 14.212,22 13.429,22L10.571,22C9.788,22 9.143,21.355 9.143,20.571L9.143,10.571ZM16.286,3.429C16.286,2.645 16.931,2 17.714,2L20.571,2C21.355,2 22,2.645 22,3.429L22,20.571C22,21.355 21.355,22 20.571,22L17.714,22C16.931,22 16.286,21.355 16.286,20.571L16.286,3.429Z"
      />
    </svg>
  );
};
